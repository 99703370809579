.step-2 {
  flex-direction: row !important;

  label {
    display: flex;
    width: 100%;
    height: 100%;

    .MuiTypography-root {
      position: absolute;
      left: 52px;
    }

    & > span {
      margin-left: auto;
      margin-right: 20px;
      font-family: "Lato", "Author", sans-serif;
      color: rgba(30, 43, 51, 1);
      font-size: 15px;
      font-weight: 700;
    }
  }

  .other-wrap {
    .on {
      display: flex;
    }

    input {
      font-size: 15px;
      color: rgba(30, 43, 51, 1);

      // &.focused {
      //   font-size: 16px;
      // }
      &.added {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        & + p {
          display: flex;
        }
      }
    }
  }  
}

.animated-button {
  animation: shake 1.2s;
  box-shadow: 0px 0px 4px rgba(251, 21, 22, 1)!important;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-2px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(2px);
  }
}

.overflow {
  animation: imgFilter 1.5s linear infinite;
  -webkit-animation: imgFilter 1.5s linear infinite;
}

@keyframes imgFilter {
  0%,
  100% {
    filter: brightness(1) contrast(1);
    -webkit-filter: brightness(1) contrast(1);
  }
  50% {
    filter: brightness(0.5) contrast(2);
    -webkit-filter: brightness(0.5) contrast(2);
  }
}
