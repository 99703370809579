.admin-page {
  color: rgba(30, 43, 51, 1);
  .aside {
    .tab {
      &.active {
        color: rgba(59, 135, 255, 1);
        background-color: rgba(59, 135, 255, 0.06);
      }
      &:hover {
        color: rgba(59, 135, 255, 1);
        background-color: rgba(59, 135, 255, 0.06);
      }
      &:nth-child(1).active,
      &:nth-child(1):hover {
        svg {
          rect {
            stroke: rgba(59, 135, 255, 1);
          }
        }
      }
      &:nth-child(2).active,
      &:nth-child(2):hover {
        path {
          stroke: rgba(59, 135, 255, 1);
        }
      }
      &:nth-child(3).active,
      &:nth-child(3):hover {
        svg {
          path {
            fill: rgba(59, 135, 255, 1);
          }
        }
      }
    }
  }
}

.settings {
  .edit-btn {
    &:hover {
      svg {
        path {
          transition: all 0.2s;
          stroke: rgba(59, 135, 255, 1);
        }
      }
    }
  }
}

.useAI-btn{
  &:hover {
    svg {
      path {
        transition: all 0.2s;
        fill: #FFFFFF;
      }
    }
  }
}
.like-btn{
  &:hover {
    svg {
      path {
        transition: all 0.2s;
        stroke: rgba(59, 135, 255, 1);
      }
    }
  }
  &.active {
    background-color: rgba(59, 135, 255, 1);
    border-color: rgba(59, 135, 255, 1);
    svg {
      path {
        transition: all 0.2s;
        stroke: #FFFFFF;
        fill: #FFFFFF;
      }
    }
  }
}

.back-btn{
  &:hover {
    svg {
      path {
        transition: all 0.2s;
        stroke: rgba(59, 135, 255, 1);
      }
    }
  }
}


.reset-filters-btn{
  &:hover {
    svg {
      opacity: 0.8;      
    }
  }
}

.calendly-popup {
  max-width: 400px !important;
  min-width: auto!important;
  height: 600px !important;
  margin-left: auto;
  margin-right: auto;

  .calendly-popup-content {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 640px) {
  .calendly-popup {
    height: 700px !important;
    top: 100px;
  }
}
@media (min-width: 1024px) {
  .calendly-popup {
    margin: 0 -15px;
  }
}
@media (min-width: 1280px) {
  .calendly-popup {
    max-width: none;
    margin: 0;
  }
}

.calendly-popup-close {
  border: 1px solid #ffffff !important;
  border-radius: 50%;
  width: 28px !important;
  height: 28px !important;
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }
}

.project-item{
  &:last-child{
    margin-bottom: 0;
  }
}


/*switch styles*/

div.btn-container{
  vertical-align: middle;
  text-align: center;
}

label {
  font-size: 15px;
  color: rgba(75, 88, 101, 1);
  font-weight: 400;
  white-space: nowrap;
}

.btn-save-mode-switch{
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-save-mode-switch > label.btn-save-mode-switch-inner{
  margin: 0px;
  width: 200px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease;
  box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;
  display: block;
  padding: 8px 10px;
  cursor: pointer;
}

.btn-save-mode-switch > label.btn-save-mode-switch-inner:before{
  content: attr(data-on);
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  top: 10px;
  right: 16px;

}

.btn-save-mode-switch > label.btn-save-mode-switch-inner:after{
  content: attr(data-off);
  width: fit-content;
  height: 40px;
  background: rgba(127, 142, 163, 1);
  color: #fff;
  border-radius: 26px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.2s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 8px 14px;
}

.btn-save-mode-switch > .alert{
  display: none;
  background: #FF9800;
  border: none;
  color: #fff;
}

.btn-save-mode-switch input[type="checkbox"]{
  cursor: pointer;
  width: 0px;
  height: 0px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-save-mode-switch input[type="checkbox"]:checked + label.btn-save-mode-switch-inner{
  background: #FFFFFF;
  color: rgba(75, 88, 101, 1);
}

.btn-save-mode-switch input[type="checkbox"]:checked + label.btn-save-mode-switch-inner:after{
  content: attr(data-on);
  left: auto;
  right: 2px;
  width: fit-content;
  background: rgba(127, 142, 163, 1);
  color: #FFFFFF;
}

.btn-save-mode-switch input[type="checkbox"]:checked + label.btn-save-mode-switch-inner:before{
  content: attr(data-off);
  right: auto;
  left: 16px;
}