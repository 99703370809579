.terms {
  .marked-item {
    position: relative;
    padding-left: 21px;

    &:first-child{
      margin-top: 8px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: rgba(59, 135, 255, 1);
      left: 0px;
      top: 10px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 1px;
      left: 5px;
      top: 12px;
      background-color: rgba(59, 135, 255, 1);
    }
  }
}
