.call {
  .calendly-inline-widget {
    max-width: 1000px;
    min-width: 100% !important;
    height: 600px!important;
  }

  @media (min-width: 640px){
    .calendly-inline-widget {
      height: 700px!important;
    }
  }
  @media (min-width: 1024px){
    .calendly-inline-widget {
      margin: 0 -15px;
    }
  }
  @media (min-width: 1280px){
    .calendly-inline-widget {
      max-width: none;
      margin: 0;
    }
  }
}
