html {
  &.dark {
    color: #eeeeee;
    background-color: #030711;
  }
}

.theme-btn {
  background-color: #ffffff;
  border: 1px solid rgba(219, 233, 255, 1);
  &:hover {
    background-color: rgba(243, 245, 247, 1);
  }
}

.overlay {
  height: 10px;
  background: linear-gradient(
    to bottom,
    rgba(59, 135, 255, 0.1) 20%,
    rgb(255, 255, 255) 100%
  );
  box-shadow: 0px -5px 25px rgba(59, 135, 255, 0.3);
}

.dark {
  #top-section {
    background: linear-gradient(to bottom, rgba(59, 135, 255, 0.3), #030711);
    // background: #030711;
    .overlay {
      height: 30px;
      background: linear-gradient(
        to bottom,
        rgba(3, 7, 17, 0.9) 20%,
        rgb(3, 7, 17) 100%
      );
      box-shadow: 0px -10px 15px rgba(3, 7, 17, 1);
    }
  }

  .main-page {
    h1 {
      text-shadow: 2px 4px 14px #030711;
      & + p {
        text-shadow: 2px 4px 14px #030711;
        filter: none;
      }
    }
  }


  .theme-btn {
    background-color: rgba(8, 14, 29, 1);
    border: 1px solid #1d283a;

    &:hover {
      background-color: rgba(29, 40, 58, 1);
    }
  }
}
