.slider-block {
  display: flex;
  justify-content: center;
}

.splide__list {
  padding: 40px 0 !important;
}
.splide__slide {
  width: 320px !important;
}
.splide__slide .card{
  height: 485px !important;
}

.splide__pagination{
  bottom: -15px;
}

.splide__pagination li{
  border: 1px solid transparent;
  border-radius: 100%;
}

.splide__pagination li:not(:last-child){
  margin-right: 10px;
}

.splide__pagination li:has(.splide__pagination__page.is-active){
  border-color: rgba(59, 135, 255, 1);
}

.splide__pagination .splide__pagination__page{
  width: 6px;
  height: 6px;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(59, 135, 255, 0.4);
}
.splide__pagination .splide__pagination__page.is-active{
  background-color: rgba(59, 135, 255, 1);
}

.splide__arrow{
  height: 46px;
  width: 46px;
  top: 110%;
  background-color: #ffffff!important;
  border: 1px solid rgba(59, 135, 255, 1);
}

.splide__arrow[disabled]{
  border-color: #ffffff;
}

.splide__arrow--prev{
  left: 50%;
}

.splide__arrow--next{
  left: 60%;
}

.splide__arrow svg {
  fill:rgba(59, 135, 255, 1);
}

@media (min-width: 768px) {
  .splide__slide {
    margin-left: 10px;
  }
  .splide__slide:nth-child(2n) {
    transform: rotate(2deg);
  }
  .splide__slide:nth-child(2n + 1) {
    transform: rotate(-2deg);
  }
}
