.services-page {
  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
    font-family: "Author", sans-serif;
    font-weight: 600;
  }
  
  p {
    margin-bottom: 7px;
    letter-spacing: 0.36px;
    span {
      color: rgba(59, 135, 255, 1);
    }
  }

  a {
    color: rgba(59, 135, 255, 1);
    &:hover {
      opacity: 0.8;
    }
  }

  ul {
    margin-top: 20px;
  }

  .list-title{
    font-weight: 900;
  }

  li.marked-item {
    padding-left: 21px;
    position: relative;
    margin-bottom: 15px;
    letter-spacing: 0.54px;
    line-height: 28px;
    list-style: none;

    span {
      color: rgba(59, 135, 255, 1);
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: rgba(59, 135, 255, 1);
    }
    &::before {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      left: 0;
      top: 12px;
    }
    &::after {
      width: 8px;
      height: 1px;
      left: 5px;
      top: 14px;
    }
  }
  
  @media (min-width: 768px) {
    font-size: 18px;
    h2 {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 34px;
    }
  }
}
