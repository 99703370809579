.dark {
  .step-6 {
    .MuiInputBase-root {
      color: rgba(238, 238, 238, 1);
    }
    input::placeholder {
      color: rgba(238, 238, 238, 0.7);
    }
  }
}

.step-6 {
  .MuiFormControl-root {
    width: 100%;
    margin: 0 auto;
  }

  .MuiFormGroup-root {
    flex-direction: row !important;
  }

  .MuiInputBase-root {
    font-family: "Lato", "Author", sans-serif;
    color: rgba(30, 43, 51, 1);
    font-size: 18px;
    font-weight: 400;
    border-radius: 8px;

    &:hover fieldset {
      border-color: rgba(59, 135, 255, 0.4);
    }
  }

  input::placeholder{
    font-family: "Lato", "Author", sans-serif;
    color: rgba(30, 43, 51, 0.7);
    font-size: 18px;
    font-weight: 400;
    opacity: 1;
  }

  fieldset {
    top: 0;
    border-color: rgba(59, 135, 255, 1);

    legend {
      display: none;
    }
  }

  .MuiFormControl-root:hover fieldset{
    border-color: rgba(59, 135, 255, 1);
  }

  @media (min-width: 640px) {
    .MuiFormControl-root {
      max-width: 350px;
    }
  }
}
