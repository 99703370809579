.step-1 {
  .MuiFormControl-root {
    flex-direction: row !important;
    width: 100%;
  }

  .MuiFormGroup-root {
    flex-direction: row !important;
  }

  label {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 100%;

    .MuiTypography-root {
      position: absolute;
      left: 52px;
    }

    & > span {
      padding: 0;
      font-family: "Lato", "Author", sans-serif;
      color: rgba(30, 43, 51, 1);
      font-size: 15px;
      font-weight: 700;

      & > span {
        margin-top: 0px !important;
        height: 0;
        width: 0;
        opacity: 0;
        padding: 0;
      }
    }
  }
}
