.set {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  img {
    position: absolute;
    display: block;
    width: 60px;
    // width: auto;
    width: initial;
    height: 120px;

    transform: translateX(-50%);

    &:nth-child(1) {
      // left: 20%;
      left: 24%;
      animation: heroMove 10s linear infinite;
      animation-delay: -1s;
    }
    &:nth-child(2) {
      // left: 40%;
      // left: 30%;
      left: 27%;
      animation: heroMove 15s linear infinite;
      animation-delay: -10s;
    }
    &:nth-child(3) {
      left: 30%;
      // left: 60%;
      animation: heroMove 20s linear infinite;
      animation-delay: -15s;
    }
    &:nth-child(4) {
      left: 67%;
      animation: heroMove 11s linear infinite;
      animation-delay: -3s;
    }
    &:nth-child(5) {
      // left: 85%;
      left: 70%;
      animation: heroMove 16s linear infinite;
      animation-delay: -9s;
    }
  }
}
.set1 {
  transform: scale(1) rotateX(180deg);
  img {
    filter: blur(0.9px);

    @media (min-width: 640px) {
      & {
        filter: blur(1.5px);
      }
    }

    &:nth-child(3) {
      display: none;

      @media (min-width: 640px) {
        & {
          display: block;
        }
      }
    }
    &:nth-child(4) {
      display: none;

      @media (min-width: 640px) {
        & {
          display: block;
        }
      }
    }
  }
}
.set2 {
  transform: scale(1.4) rotateY(180deg);
  img {
    filter: blur(0.5px);

    &:nth-child(2) {
      display: none;

      @media (min-width: 640px) {
        & {
          display: block;
        }
      }
    }
    &:nth-child(5) {
      display: none;

      @media (min-width: 640px) {
        & {
          display: block;
        }
      }
    }
  }
}
.set3 {
  transform: scale(2);
}

.svg-wrapper {
  position: relative;
  display: inline-flex;
  margin-right: 100%;
}

.border-scale {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  transition-property: opacity;
  transition-duration: 1000ms;
  will-change: transform, border-radius, opacity;
  z-index: -1;
  transform: scale(0.95);
}

#top-section {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(59, 135, 255, 0.6), #fff);

  .container {
    background: transparent;
  }
}

.we-attract-section {
  li {
    background: linear-gradient(
      to top,
      rgba(219, 233, 255, 0.5) 10%,
      white 30%,
      white 70%,
      rgba(219, 233, 255, 0.5) 90%
    );

    .support {
      g {
        path {
          animation: support-center 2s ease-in infinite;
        }
      }

      path {
        transform-origin: center;
        animation: support-extra 2s ease-in-out infinite;
      }
    }

    .medical {
      path {
        transform-origin: center;
        animation: medical 2s ease-in infinite;
      }
    }

    .tech {
      g {
        transform-origin: center;
        animation: tech-extra 3s ease-in infinite;

        path {
          &:nth-child(2) {
            transform-origin: center;
            animation: tech-center 1.3s ease-in infinite;
          }
          &:nth-child(3) {
            transform-origin: center;
            animation: tech-center 1.3s ease-in infinite;
            animation-delay: 0.2s;
          }
          &:nth-child(4) {
            transform-origin: center;
            animation: tech-center 1.3s ease-in infinite;
            animation-delay: 0.3s;
          }
        }
      }
    }

    .legal-blue {
      path {
        &:last-of-type {
          animation: legal-extra 4s linear infinite;
        }
      }

      .wrap ~ *:not(path:last-of-type) {
        animation: legal-center 4s linear infinite;
      }
    }

    .benefits-blue {
      path {
        animation: benefits-extra 4s linear infinite;
        transform-origin: center;
      }

      path {
        &:first-of-type {
          animation: benefits-center 4s ease-in infinite;
        }
      }
    }

    &:hover {
      .border-scale {
        animation: scaleBorder 3s ease-in-out infinite;
      }
      .border-scale-2 {
        animation: scaleBorder 3s ease-in-out infinite 1.2s;
      }
    }
  }
}

.services-section {
  .svg-wrapper {
    margin-right: 1rem;
  }
  li {
    &:hover {
      .staffing-blue {
        path {
          transform-origin: center;
          animation: scale 3s ease-in infinite;
        }
      }
      .recruiting-blue {
        path {
          transform-origin: center;
          animation: scale 3s ease-in infinite;
        }
      }
      .border-scale {
        animation: scaleBorder 3s ease-in-out infinite;
      }
      .border-scale-2 {
        animation: scaleBorder 3s ease-in-out infinite 1.2s;
      }
    }
  }
}

.pricing-section {
  .staffing-blue {
    path {
      animation: scale 3s ease-in infinite;
      transform-origin: center;
    }
  }
  .recruiting-blue {
    path {
      animation: scale 3s ease-in infinite 1s;
      transform-origin: center;
    }
  }

  .clutch {
    .border-scale {
      transform: scale(0.8);
      opacity: 0;
    }
    &:hover {
      .border-scale {
        animation: scaleClutchBorder 2s ease-in-out;
      }
    }
  }
}

// =============================================================================================
.team {
  path[stroke="#3B87FF"],
  path[fill="#3B87FF"],
  circle[stroke="#3B87FF"] {
    transform-origin: center;
    animation: team-center 3s ease-in infinite;
  }

  path[stroke="#1E2B33"] {
    transform-origin: center;
    animation: team-extra 3s ease-in infinite;
  }
}

.recruiting-svg {
  path {
    animation: benefits-extra 4s linear infinite;
    transform-origin: center;
  }

  g {
    animation: benefits-center 4s ease-in infinite;
    path {
      animation: none;
    }
  }
}

.answer-svg {
  g {
    transform-origin: center;
    animation: tech-extra 2s ease-in infinite;

    path {
      &:nth-child(2) {
        transform-origin: center;
        animation: tech-center 1.5s ease-in infinite;
      }
    }
  }
}

.confirm {
  path[stroke="#3B87FF"]{
    transform-origin: center;
    animation: fade-move-down 2s ease-in infinite;
  }
  path[stroke="#1E2B33"]  {
    transform-origin: center;
    animation: rotate-360-scale 2s linear infinite;
  }
}

.matching-svg {
  path[stroke="#1E2B33"] {
    transform-origin: center;
    animation: support-center 2s ease-in infinite;
    animation-delay: 1s;
  }
  path[stroke="#3B87FF"] {
    transform-origin: center;
    animation: support-extra 2s ease-in infinite;
  }
}

@keyframes heroMove {
  0% {
    opacity: 0;
    top: -10%;
    transform: translateX(20px) rotate(0deg);
  }
  10% {
    opacity: 1;
  }
  20% {
    transform: translateX(-20px) rotate(45deg);
  }
  40% {
    transform: translateX(-20px) rotate(90deg);
  }
  60% {
    transform: translateX(20px) rotate(135deg);
  }
  80% {
    transform: translateX(-20px) rotate(180deg);
  }
  100% {
    top: 105%;
    transform: translateX(-20px) rotate(225deg);
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotateScale {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(30deg) scale(0.85);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes upDownScale {
  0% {
    transform: translateY(0) scale(1);
  }
  25% {
    transform: translateY(-4px) scale(0.9);
  }
  50% {
    transform: translateY(0) scale(1);
  }
  75% {
    transform: translateY(4px) scale(0.9);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}
@keyframes rotateYscale {
  0% {
    transform: rotateY(0deg) scale(1);
  }
  50% {
    transform: rotateY(180deg) scale(0.85);
  }
  100% {
    transform: rotateY(0deg) scale(1);
  }
}
@keyframes scaleBorder {
  0% {
    transform: scale(0.95);
    opacity: 1;
  }

  60% {
    // opacity: 0.6;
    opacity: 0.4;
  }
  95% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes scaleClutchBorder {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  60% {
    opacity: 0.4;
  }
  95% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes colorPulse {
  0% {
    border-color: rgba(219, 233, 255, 1);
  }
  50% {
    border-color: rgba(59, 135, 255, 0.5);
  }
  100% {
    border-color: rgba(219, 233, 255, 1);
  }
}

@keyframes support-extra {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(0.5);
    opacity: 0;
  }
  85% {
    transform: scale(1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes support-center {
  0% {
    transform: scale(0.85);
  }
  15% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
  85% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(0.85);
  }
}

@keyframes medical {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.08);
  }
  65% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.08);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes team-extra {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes team-center {
  0% {
    transform: scale(1) translate3d(0, 0, 0);
  }
  15% {
    transform: scale(1.1) translate3d(0, 0, 0);
  }
  30% {
    transform: scale(1.1) translate3d(-2px, 1px, 0);
  }
  45% {
    transform: scale(1) translate3d(0, 0px, 0);
  }
  60% {
    transform: scale(1.1) translate3d(2px, 1px, 0);
  }
  75% {
    transform: scale(1.1) translate3d(2px, 1px, 0);
  }
  90% {
    transform: scale(1) translate3d(0px, 0px, 0);
  }
  100% {
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes tech-extra {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1.5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(1.5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes tech-center {
  0% {
    transform: translateY(1px);
  }
  10% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(-2px);
  }
  75% {
    transform: translateY(-1px);
  }
  90% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(1px);
  }
}

@keyframes legal-center {
  0% {
    transform: translateX(-5px);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(-5px);
  }
}
@keyframes legal-extra {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(-25px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes benefits-extra {
  0% {
    transform: rotate(0deg) scale(1.1);
  }
  50% {
    transform: rotate(180deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1.1);
  }
}
@keyframes benefits-center {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1.5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(1.5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rotate-360-scale {
  0% {
    transform: rotate(0deg) scale(1.1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1.1);
  }
}

@keyframes fade-move-down {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  10% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
  }
}

// html.dark + * + body {
//   @keyframes colorPulse {
//     0% {
//       border-color: rgba(29, 40, 58, 1);
//     }
//     75% {
//       border-color: rgba(59, 135, 255, 0.4);
//     }
//     100% {
//       border-color: rgba(29, 40, 58, 1);
//     }
//   }
// }

.MuiFormGroup-root {
  & > div {
    box-shadow: 0 0 15px 1px inset rgba(0, 0, 0, 0.1);
  }
}

.MuiSwitch-switchBase {
  margin-top: 1.3px !important;
  background-image: linear-gradient(45deg, white, gray);
  background-image: radial-gradient(white 0, #eee 60%, gray 100%);
  color: transparent !important;

  & + .MuiSwitch-track {
    box-shadow: inset 0 0 10px rgba(62, 64, 66, 0.1),
      inset 0 0 0 1.5px rgba(62, 64, 66, 0.1);
    border-radius: 20px;
    background-color: transparent !important;
    background-image: linear-gradient(
      to top,
      rgba(222, 223, 225, 1),
      rgba(222, 223, 225, 0.5) 45%,
      rgba(222, 223, 225, 0.5) 55%,
      rgba(222, 223, 225, 1) 100%
    );
  }

  &.Mui-checked {
    &:after {
      background-image: linear-gradient(
        0deg,
        #1976d2,
        rgba(59, 135, 255, 0.4) 90%
      );
      box-shadow: 0 0 0 1px rgba(59, 135, 255, 0.4);
    }
    & + .MuiSwitch-track {
      background-color: transparent !important;
      background-image: linear-gradient(
        0deg,
        rgba(59, 135, 255, 1),
        rgba(59, 135, 255, 0.6) 45%,
        rgba(59, 135, 255, 0.6) 55%,
        rgba(59, 135, 255, 1) 100%
      );
    }
  }

  &:after {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    top: calc(50% - 2px);
    right: 5px;
    border-radius: 2px;
    background-image: linear-gradient(0deg, #dedfe1, gray);
    box-shadow: 0 0 0 1px #dedfe1;
  }
}

[data-aos] svg {
  opacity: 0;
  transition: opacity 1s ease;
}

[data-aos].aos-animate svg {
  opacity: 1;
}
