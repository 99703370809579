.hear li:nth-child(1){
    transform: rotate(-4deg)!important;
}
.hear li:nth-child(2){
    transform: rotate(0.5deg)!important;
}
.hear li:nth-child(3){
    transform: rotate(3deg)!important;
}
.hear li:nth-child(4){
    transform: rotate(-1deg)!important;
}