.step-3 {
  flex-direction: row!important; 

  label {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .MuiTypography-root{
      position: absolute;
      left: 20px;
    }
    
    & > span {
      margin-left: auto;
      margin-right: 20px;
      font-family: "Lato", "Author", sans-serif;
      color: rgba(30, 43, 51, 1);
      font-size: 15px;
      font-weight: 700;

      // & > span {
      //   margin-top: 0px !important;
      // }
    }
  }
}
