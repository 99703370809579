.step-4 {
  flex-direction: row!important; 

  label {
    display: flex;
    padding-left: 10px;
    width: 100%;
    height: 100%;

    .MuiTypography-root{
      position: absolute;
      left: 20px;
    }
    
    & > span {
      margin-left: auto;
      margin-right: 20px;
      font-family: "Lato", "Author", sans-serif;
      color: rgba(30, 43, 51, 1);
      font-size: 15px;
      font-weight: 700;
    }
  }

  .other-wrap {
    .on {
      display: flex;
    }

    input {
      font-size: 15px;
      color: rgba(30, 43, 51, 1);

      // &.focused {
      //   font-size: 16px;
      // }
      &.added {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        & + p {
          display: flex;
        }
      }
    }
  }
}
