.cookies {
  .list {
    padding-left: 25px;
  }

  ol {
    counter-reset: section;

    &:not(.list-wrap) {
      padding-left: 0;
    }

    li {
      margin-bottom: 5px;
      list-style-type: none;

      //   p{
      //     margin-left: 10px;
      //   }

      &::before {
        counter-increment: section;
        content: counters(section, ".") " ";
        list-style-type: none;
        margin-right: 5px;
      }
    }
  }
  .list-wrap {
    & > li {
      list-style-type: auto;

      &::marker {
        font-size: 17px;
        font-weight: 600;
      }

      &::before {
        counter-increment: section;
        content: "";
        list-style-type: none;
        margin-right: 0;
      }

      & ol {
        margin-left: -20px;

        @media (min-width: 768px) {
          & {
            margin-left: 0;
          }
        }
      }
    }
  }
}
