.header-admin {
  .nav {
    .tab {
      &.active {
        color: rgba(59, 135, 255, 1);
        background-color: rgba(59, 135, 255, 0.06);
      }
      &:hover {
        color: rgba(59, 135, 255, 1);
        background-color: rgba(59, 135, 255, 0.06);
      }
      &:nth-child(1).active,
      &:nth-child(1):hover {
        svg {
          rect {
            stroke: rgba(59, 135, 255, 1);
          }
        }
      }
      &:nth-child(2).active,
      &:nth-child(2):hover {
        path {
          stroke: rgba(59, 135, 255, 1);
        }
      }
      &:nth-child(3).active,
      &:nth-child(3):hover {
        svg {
          path {
            fill: rgba(59, 135, 255, 1);
          }
        }
      }
    }
  }
}

.dark {
  .header-admin {
    .nav {
      .tab {
        &:hover {
          .tab-label {
            color: rgba(59, 135, 255, 1);
          }
        }
        &.active {
          .tab-label {
            color: rgba(59, 135, 255, 1);
          }
        }
      }
    }
  }
}
