@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Lato", "Author", sans-serif, system-ui;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(30, 43, 51, 1);
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    scroll-behavior: smooth;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=author@600,700&display=swap");

body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

body:not(:has(header.hidden)):not(:has(section.login)):not(:has(.admin-page)) {
  background-image: url("./img/overlay.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

body:has(.admin-page) {
  background-color: rgba(240, 242, 244, 1);
}

main:has(div.pitch) + footer {
  background-color: transparent;
}

main:has(div.final) + footer {
  background-color: transparent;
}

main:has(div.pitch) + footer a.group,
main:has(div.final) + footer a.group {
  display: none;
}

.diagram {
  position: relative;
}
.diagram img {
  position: relative;
  z-index: 1;
}

.diagram:after {
  content: "";
  position: absolute;
  bottom: -16px;
  width: 269px;
  height: 90px;
  background-image: url("./img/diagram-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.MuiSnackbar-root {
  background-color: transparent !important;
}
.MuiSnackbar-root .MuiPaper-root {
  flex-wrap: nowrap;
  padding: 10px 12px;
  background-color: #d8e7ff !important;
  transition-duration: 0.5s !important;
}
.MuiSnackbar-root .MuiSnackbarContent-message {
  padding: 0;
}
.MuiSnackbar-root .MuiSnackbarContent-action {
  padding-left: 8px;
  margin-right: 0;
}

@media (min-width: 600px) {
  .MuiSnackbar-root {
    right: 24px !important;
    left: auto !important;
  }
}

@media (min-width: 768px) {
  .diagram:after {
    content: "";
    position: absolute;
    bottom: -33px;
    width: 550px;
    height: 142px;
    background-image: url("./img/diagram-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }
}

@media (min-width: 1024px) {
  main:has(div.pitch) + footer .nav a::before {
    background-color: #ffffff;
  }
  main:has(div.final) + footer .nav a::before {
    background-color: #ffffff;
  }

  main:has(div.pitch) + footer a.group,
  main:has(div.final) + footer a.group {
    display: flex;
    opacity: 0;
    pointer-events: none;
  }
}

@media (min-width: 1280px) {
  body:not(:has(header.hidden)) {
    background-position-y: 140%;
  }
}

@media (min-width: 1536px) {
  header.header-pitch {
    border-bottom-width: 0;
  }

  header.header-pitch::after {
    content: "";
    width: 1440px;
    height: 1px;
    display: block;
    background-color: rgba(59, 135, 255, 0.4);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  main:has(div.final) + footer {
    border-top-width: 0;
  }
  main:has(div.final) + footer::before {
    content: "";
    width: 1440px;
    height: 1px;
    display: block;
    background-color: rgba(59, 135, 255, 0.4);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

#root {
  display: flex;
  flex-grow: 1;
}

.root-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

svg.MuiSvgIcon-root path {
  fill: rgba(59, 135, 255, 1) !important;
  stroke-width: 1px !important;
}

button[data-rmiz-btn-unzoom] {
  background-color: #3b87ff !important;
  outline: none;
}
